import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../components/PageBanner';
import Sponsors from '../components/Sponsors';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={6} title={"Nos Sponsors"} mdxType="PageBanner" />
    <section id="one">
      <div className="inner"> 
        <Sponsors mdxType="Sponsors" />
        <p></p>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      